import { useEffect, useContext } from "react";
import { connect } from "react-redux";
import LocationInspiration from "app/pages/SmartDP/Search/LocationInspiration/LocationInspiration";
import { getInspirationMerchandising } from "app/pages/Merchandising/merchandisingSelector";
import { getPopularProducts } from "app/pages/Listing/productsSelector";
import { fetchProducts } from "app/pages/Listing/productActionCreators";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";

const LocationInspirationContainer = ({
	fetchProducts = () => {},
	inspirationMerchandising,
	popularProducts,
}) => {
	const { productsVisibility, partnerCode, shop } = useContext(AppGlobalsContext);

	useEffect(() => {
		// do not fetch products twice in /listing for example
		if (popularProducts?.length === 0) {
			fetchProducts({ productsVisibility, shop, partnerCode });
		}
	}, []);

	return (
		<LocationInspiration
			inspirationMerchandising={inspirationMerchandising}
			popularProducts={popularProducts}
		/>
	);
};

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			fetchProducts,
		},
		dispatch
	),
});

const mapStateToProps = state => ({
	inspirationMerchandising: getInspirationMerchandising(state),
	popularProducts: getPopularProducts(state),
});

LocationInspirationContainer.propTypes = {
	inspirationMerchandising: PropTypes.object,
	popularProducts: PropTypes.array,
	fetchProducts: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LocationInspirationContainer);
