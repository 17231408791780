import { connect } from "react-redux";
import get from "lodash/get";
import { bindActionCreators } from "redux";
import addHours from "date-fns/addHours";
import { useEffect } from "react";
import SmartDPSearchForm from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchForm";
import { fetchSDPFilters } from "app/pages/SmartDP/smartDPActionCreators";
import PropTypes from "prop-types";
import { getTopDestinations } from "app/reducers/shopSelector";
import { BRANDS, QUOTATION_CODE_STATUS } from "app/constants";
import LocationInspirationContainer from "app/pages/SmartDP/Search/LocationInspiration/LocationInspirationContainer";
import { RESOLUTION } from "app/constants";

const SmartDPSearchFormContainer = props => {
	const { fetchSDPFilters, isMobile, brand } = props;
	const isBrandAF = brand === BRANDS.AF;

	useEffect(() => {
		fetchSDPFilters();
	}, []);

	return (
		<div className="sdp-search-form__container">
			<SmartDPSearchForm {...props} />
			{isBrandAF && isMobile && <LocationInspirationContainer />}
		</div>
	);
};

SmartDPSearchFormContainer.propTypes = {
	fetchSDPFilters: PropTypes.func,
	isMobile: PropTypes.bool,
	brand: PropTypes.string,
};

const mapStateToProps = state => {
	const currentMerchCode = state.merchandising?.current?.code;

	// because there is a SmartDPsearchFormContainer is sdp/search and /merch pages, we need
	// to know from where we initialise the form
	const booking = currentMerchCode ? state.booking.smartDPMerch : state.booking;

	const initialValues = {
		travelDates: {},
	};

	if (get(state, "smartDP.cabins[0]")) {
		initialValues.cabin = get(state, "smartDP.cabins[0]");
	}

	if (booking.cabin?.code) {
		initialValues.cabin = booking.cabin;
	}

	if (booking.destinationResort?.code && state.smartDP.destinations) {
		const destination = state.smartDP.destinations.find(
			destination => destination.code.toString() === booking.destinationResort.code
		);
		if (destination) {
			initialValues.destinationResort = {
				...booking.destinationResort,
				departureDateStart: destination.departureDateStart,
				departureDateEnd: destination.departureDateEnd,
			};
		}
	}
	//
	if (booking.departureCity?.code) {
		initialValues.departureCity = booking.departureCity;
	}

	if (booking.departureDate) {
		// @see SmartDPSearch in handleFormSubmission
		// ex Paris : Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		let departureDate = new Date(booking.departureDate);
		// => Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
		departureDate = addHours(departureDate, departureDate.getTimezoneOffset() / 60);
		// departureDate.geTime() gives 10/6/2021 à 22:00:00
		initialValues.travelDates.departureDate = departureDate;
	}

	if (booking.endDate) {
		// @see SmartDPSearch in handleFormSubmission
		// ex Paris : Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		let endDate = new Date(booking.endDate);
		// => Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
		endDate = addHours(endDate, endDate.getTimezoneOffset() / 60);
		// endDate.geTime() gives 10/6/2021 à 22:00:00
		initialValues.travelDates.endDate = endDate;
	}

	if (booking.occupancies?.length > 0) {
		initialValues.occupancies = booking.occupancies;
	}

	if (booking.duration?.value) {
		initialValues.duration = {
			code: booking.duration.value,
		};
	}

	return {
		initialValues,
		destinations: state.smartDP.destinations,
		departureCities: state.smartDP.departureCities,
		cabins: state.smartDP.cabins,
		validateOnMount: state.smartDP.status === QUOTATION_CODE_STATUS.BAD_REQUEST,
		topDestinationsList: getTopDestinations(state) || [],
		isMobile: [RESOLUTION.SMALL, RESOLUTION.MEDIUM].includes(state?.resolution),
		brand: state.brand.code,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPFilters,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPSearchFormContainer);
