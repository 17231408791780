import PropTypes from "prop-types";
import initial from "lodash/initial";
import List from "app/pages/.shared/List";
import { FormattedMessage, useIntl } from "react-intl";
import { PAYMENT_METHODS, PAYMENT_OPTIONS } from "app/constants";

const PaymentModesTooltip = ({ paymentModes = [] }) => {
	const intl = useIntl();

	const paymentTermsAlma = paymentModes
		.filter(
			paymentMode =>
				paymentMode?.type && paymentMode.type.toLowerCase() === PAYMENT_METHODS.ALMA
		)
		.map(item => item.terms);
	const paymentModesInfo = [
		<FormattedMessage
			id="payment.options.popover.message.2"
			key="payment.options.popover.message.2"
		/>,
	];
	const hasPaymentMode2X = paymentModes.find(
		paymentMode => paymentMode.terms === PAYMENT_OPTIONS["2x"]
	);
	const hasPaymentMode4XCasino = paymentModes
		.filter(
			paymentMode =>
				paymentMode?.type && paymentMode.type.toLowerCase() === PAYMENT_METHODS.CASINO
		)
		.find(paymentMode => paymentMode.terms === PAYMENT_OPTIONS["4x"]);

	const hasPaymentAlma = paymentModes.some(
		paymentMode => paymentMode?.type && paymentMode.type.toLowerCase() === PAYMENT_METHODS.ALMA
	);

	if (hasPaymentMode2X) {
		paymentModesInfo.push(
			<FormattedMessage
				id="payment.options.popover.message.3.1"
				key="payment.options.popover.message.3"
			/>
		);
	}

	if (hasPaymentMode4XCasino) {
		paymentModesInfo.push(
			<FormattedMessage
				id="payment.options.popover.message.4"
				key="payment.options.popover.message.4"
				values={{
					terms: "4x",
				}}
			/>
		);
	}

	if (hasPaymentAlma) {
		paymentModesInfo.push(
			<FormattedMessage
				id="payment.options.popover.message.4"
				key="payment.options.popover.message.4"
				values={{
					terms:
						paymentTermsAlma.length > 1 ? (
							<FormattedMessage
								id="available.payment.terms.list"
								values={{
									firstTerms: initial(paymentTermsAlma)
										.map(term =>
											intl.formatMessage(
												{ id: "general.payment.term.short" },
												{ term }
											)
										)
										.join(", "),
									lastTerms: (
										<FormattedMessage
											id="general.payment.term.short"
											values={{
												term: paymentTermsAlma[paymentTermsAlma.length - 1],
											}}
										/>
									),
								}}
							/>
						) : (
							<FormattedMessage
								id="general.payment.term.short"
								values={{ term: paymentTermsAlma[0] }}
							/>
						),
				}}
			/>
		);
	}

	return (
		<>
			<div>
				<FormattedMessage id="payment.options.popover.message.1" />
			</div>
			<List items={paymentModesInfo} />
		</>
	);
};

PaymentModesTooltip.propTypes = {
	paymentModes: PropTypes.arrayOf(PropTypes.number),
};

export default PaymentModesTooltip;
