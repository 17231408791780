import { memo, useContext, useRef, useState, useLayoutEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import LookAlikeProduct from "app/pages/.shared/LookAlikeProduct/LookAlikeProduct";
import AppGlobalsContext from "app/AppGlobalsContext";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import { FLAGSHIP_EVENT_KPIS, RESOLUTION } from "app/constants";
import IconOpenInNew from "app/pages/.shared/IconOpenInNew";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { stringify } from "qs";
import "./LocationInspiration.scss";
import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";

const IMG_SIZES_DESKTOP = {
	large: { width: 328, height: 98 },
	xlarge: { width: 328, height: 98 },
	medium: { width: 328, height: 98 },
	small: { width: 343, height: 98 },
};

const LocationInspiration = ({ inspirationMerchandising = {}, popularProducts = [] }) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;
	const [isTitleLineClamped, updateTitleLineClamped] = useState(false);
	const titleRef = useRef();
	const { hit: fsHit } = useFlagship();

	useLayoutEffect(() => {
		if (inspirationMerchandising?.label && titleRef.current) {
			updateTitleLineClamped(
				titleRef.current.offsetHeight < titleRef.current.scrollHeight === true
			);
		}
	}, [titleRef, inspirationMerchandising?.label]);

	const hasData =
		!isEmpty(inspirationMerchandising) && popularProducts && popularProducts.length > 0;

	const merchTitleClassNames = classNames({
		"location-inspiration__merch-title": true,
		"location-inspiration__merch-title--long": isTitleLineClamped,
	});

	const locationInspirationClassNames = classNames({
		"location-inspiration": true,
		"location-inspiration--no-data": !hasData,
	});

	const handleFlagShipMerchEvent = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FLAGSHIP_EVENT_KPIS.CLICK_ON_SEARCH_BANNER,
		});
	}, []);

	const handleFlagShipPopularOffersEvent = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FLAGSHIP_EVENT_KPIS.CLICK_ON_SEARCH_POPULAR_OFFERS,
		});
	}, []);

	return (
		<div className={locationInspirationClassNames}>
			{!isEmpty(inspirationMerchandising) && (
				<>
					<div className="location-inspiration__title">
						<FormattedMessage id="sdp.search.inspiration.title" />
					</div>
					<div
						className="location-inspiration__merch"
						data-testid="location-inspiration-merch"
					>
						<RelativeLink
							to={{
								pathname: "/merch",

								search: `?${stringify({ code: inspirationMerchandising?.code })}`,
							}}
							target="_blank"
							onClick={handleFlagShipMerchEvent}
						>
							<div className="location-inspiration__merch-cta">
								<BackgroundProgressiveImg
									sizes={IMG_SIZES_DESKTOP}
									cloudinaryOptions={["c_fill"]}
									alt={inspirationMerchandising.label}
									src={
										isMobile
											? inspirationMerchandising?.photoSmall?.url
											: inspirationMerchandising?.photoLarge?.url
									}
									thumbnail={
										isMobile
											? inspirationMerchandising?.photoSmall?.thumbnail
											: inspirationMerchandising?.photoLarge?.thumbnail
									}
									backgroundPosition="center"
								/>
								<div className={merchTitleClassNames}>
									<span ref={titleRef}>{inspirationMerchandising?.label}</span>
									<IconOpenInNew className="location-inspiration__merch-icon" />
								</div>
							</div>
						</RelativeLink>
					</div>
				</>
			)}
			{popularProducts?.length > 0 && (
				<>
					<div className="location-inspiration__title">
						<FormattedMessage id="sdp.search.inspiration.popular.sales" />
					</div>
					<div
						className="location-inspiration__sales"
						data-testid="location-inspiration-sales"
					>
						<div className="location-inspiration__sales-list">
							{popularProducts.map(product => (
								<LookAlikeProduct
									key={product.uri}
									product={product}
									resolution={resolution}
									showFromInfo={false}
									handleClick={handleFlagShipPopularOffersEvent}
								/>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

LocationInspiration.propTypes = {
	inspirationMerchandising: PropTypes.object,
	popularProducts: PropTypes.array,
};

export default memo(LocationInspiration);
