import Drawer from "../../../.shared/Drawer/Drawer";
import IconClose from "app/pages/.shared/IconClose";
import { Fragment, memo, useCallback, useRef, useState } from "react";
import { useField } from "formik";
import classNames from "classnames";
import PropTypes from "prop-types";
import { messagePropType } from "app/utils/propTypes";
import AdvancedSelectFormik from "app/pages/.shared/form/AdvancedSelectFormik/AdvancedSelectFormik";
import "./SmartDPInputSidePanelContent.scss";
import { isEmpty } from "lodash";
import useVisualViewport from "app/utils/hooks/useVisualViewport";

const SmartDPInputSidePanelContent = props => {
	const [isOpen, setOpen] = useState(false);
	const {
		id,
		label,
		className,
		isRequired,
		icon,
		drawerInputLabel,
		getOptionLabel = () => {},
		isSearchable = true,
		openMenuOnFocus,
	} = props;
	const [field, meta, helpers] = useField(props);
	const { setError } = helpers;
	const selectRef = useRef();

	const inputClassName = classNames("smartdp-input-search-select", className, {
		"smartdp-input-search-select--required": isRequired,
		"smartdp-input-search-select--touched": field.value,
		"smartdp-input-search-select--error": meta.touched && meta.error,
		"smartdp-input-search-select--with-icon": icon,
	});

	const [viewports, hasKeyboard] = useVisualViewport();

	const handleInputValueChange = useCallback(option => {
		helpers.setValue(option);
		helpers.setTouched(true);
		if (!isEmpty(option)) {
			handleClose();
		}
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleOpen = useCallback(() => {
		setOpen(true);
		setError();
	}, []);

	return (
		<Fragment>
			<div className={inputClassName} onClick={handleOpen}>
				{icon && <div className="smartdp-input-search-select__icon">{icon}</div>}
				<label htmlFor={id} className="smartdp-input-search-select__label">
					{label}
				</label>
				{field.value && (
					<div className="smartdp-input-search-select__input">
						{getOptionLabel(field.value)}
					</div>
				)}
			</div>
			<Drawer
				isOpen={isOpen}
				direction={"rtl"}
				sidePanelClassName="smartDP-input-side-panel"
				drawerClassName="smartDP-input-side-panel__content"
				style={{ height: viewports?.visualViewport?.height }}
			>
				<div className="advanced-select__container">
					<div className="advanced-select__header">
						<h3>{label}</h3>
						<div className="advanced-select__header-icon">
							<IconClose width={24} height={24} onClick={handleClose} />
						</div>
					</div>
				</div>
				<AdvancedSelectFormik
					{...props}
					label={drawerInputLabel ? drawerInputLabel : label}
					autoFocus={isSearchable}
					isClearable={isSearchable}
					selectRef={selectRef}
					classNamePrefix="advanced-select__select-sdp"
					onChange={handleInputValueChange}
					menuIsOpen={openMenuOnFocus}
					captureMenuScroll={false}
					menuShouldBlockScroll={hasKeyboard}
					menuPortalTarget={null} // mode portal par défaut, on force à null pour éviter pb affichage sur mobile quand le side panel s'ouvre
				/>
			</Drawer>
		</Fragment>
	);
};

SmartDPInputSidePanelContent.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	value: PropTypes.any,
	isSearchable: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	getOptionValue: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.array,
	onMenuOpen: PropTypes.func,
	onMenuClose: PropTypes.func,
	isRequired: PropTypes.bool,
	icon: PropTypes.element,
	keyCountMinToOpenMenu: PropTypes.number,
	popperWidth: PropTypes.number,
	components: PropTypes.object,
	openMenuOnFocus: PropTypes.bool,
	drawerInputLabel: messagePropType,
};

export default memo(SmartDPInputSidePanelContent);
