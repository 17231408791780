import { components } from "react-select";
import PropTypes from "prop-types";
import { memo } from "react";
import "./LocationMenuList.scss";

const LocationMenuListExtension = ({ children, ...props }) => {
	const { selectProps } = props;
	return (
		<components.MenuList
			className="location-menu-list location-menu-list--extension"
			{...props}
		>
			<div className="location-menu-list__options">
				{selectProps.isTopLocationMenuDisplayed && (
					<h4 className="location-menu-list__top-departure-cities-label">
						{selectProps.locationMenuLabel}
					</h4>
				)}
				<div className="location-menu-list__container">{children}</div>
			</div>
			<div className="location-menu-list__extend">{selectProps.menuListExtension}</div>
		</components.MenuList>
	);
};

LocationMenuListExtension.propTypes = {
	selectProps: PropTypes.object,
};

export default memo(LocationMenuListExtension);
