import { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { messagePropType } from "app/utils/propTypes";
import "./TravellersRoomButton.scss";
import classNames from "classnames";
import TravellersRoomForm from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomForm";
import {
	autoUpdate,
	FloatingPortal,
	offset,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useInteractions,
} from "@floating-ui/react";
import { useFormikContext } from "formik";
import useOverlay from "app/utils/hooks/useOverlay";

const TravellersRoomButton = props => {
	const [open, setOpen] = useState(false);

	const { errors, validateForm, values: formValues } = useFormikContext();

	const {
		label,
		className,
		icon,
		initialValues = [],
		valueToDisplay,
		onConfirmation = () => {},
		id,
		stickyMode = false,
		validateOnMount,
		componentToOverlayRef = {},
		overlayClassNames,
	} = props;

	useOverlay(componentToOverlayRef, open, overlayClassNames);

	const inputClassName = classNames(
		"travellers-room-button",
		"travellers-room-button--touched",
		className,
		{
			"travellers-room-button--error": errors?.occupancies,
			"travellers-room-button--with-icon": icon,
			"travellers-room-button--opened": open,
		}
	);

	const { context, x, y, strategy, refs } = useFloating({
		placement: "bottom",
		whileElementsMounted: autoUpdate,
		open,
		onOpenChange: setOpen,
		middleware: [offset(), shift()],
	});

	const handleValidation = useCallback(
		values => {
			setOpen(false);

			// relaunch validation to remove error color if there is no errors
			validateForm({
				...formValues,
				...values,
			});

			onConfirmation(values.occupancies);
		},
		[refs?.reference?.current, formValues]
	);

	const dismiss = useDismiss(context);
	// gere le click et le focus par clavier
	const focus = useFocus(context, {
		keyboardOnly: false,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, focus]);

	const handleScroll = useCallback(() => {
		document.activeElement.blur();
	}, []);

	useEffect(() => {
		// Ne pas supprimer cette condition "Cypress === "undefined"", sinon les tests e2e peuvent échouer
		if (typeof Cypress === "undefined") {
			window.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (typeof Cypress === "undefined") {
				window.removeEventListener("scroll", handleScroll);
			}
		};
	}, [handleScroll]);
	return (
		<>
			<div
				className={inputClassName}
				ref={refs.setReference}
				id={id}
				tabIndex="0"
				{...getReferenceProps({})}
			>
				<label className="travellers-room-button__label">{label}</label>
				<div className="travellers-room-button__input" data-testid="travellers-room-button">
					{icon && <div className="travellers-room-button__icon">{icon}</div>}
					{valueToDisplay}
				</div>
			</div>
			<FloatingPortal preserveTabOrder={true}>
				{open && (
					<div
						ref={refs.setFloating}
						className={classNames({
							"travellers-room-button__popover": true,
							"travellers-room-button__popover--sticky": stickyMode,
						})}
						{...getFloatingProps({
							style: {
								position: strategy,
								left: x + 90 ?? "",
								top: y ?? "",
							},
						})}
					>
						<div className="travellers-room-button__popover-body">
							<TravellersRoomForm
								initialValues={initialValues}
								handleValidation={handleValidation}
								validateOnMount={validateOnMount}
							/>
						</div>
					</div>
				)}
			</FloatingPortal>
		</>
	);
};

TravellersRoomButton.propTypes = {
	label: messagePropType,
	className: PropTypes.string,
	icon: PropTypes.element,
	valueToDisplay: PropTypes.element,
	onConfirmation: PropTypes.func,
	initialValues: PropTypes.array,
	id: PropTypes.string,
	stickyMode: PropTypes.bool,
	validateOnMount: PropTypes.bool,
	componentToOverlayRef: PropTypes.object,
	overlayClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default memo(TravellersRoomButton);
