import { components } from "react-select";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import "./LocationMenuList.scss";

const LocationMenuList = ({ children, ...props }) => {
	const { selectProps } = props;
	useEffect(() => {
		const selectedEl = document.getElementsByClassName("location-menu-list")[0];
		if (selectedEl) {
			selectedEl.scrollTo(0, 0);
		}
	}, []);

	return (
		<components.MenuList className="location-menu-list" {...props}>
			{selectProps.isTopLocationMenuDisplayed && (
				<h4 className="location-menu-list__top-departure-cities-label">
					{selectProps.locationMenuLabel}
				</h4>
			)}
			<div className="location-menu-list__container">{children}</div>
		</components.MenuList>
	);
};

LocationMenuList.propTypes = {
	selectProps: PropTypes.object,
};

export default memo(LocationMenuList);
