import AppGlobalsContext from "app/AppGlobalsContext";
import Button from "app/pages/.shared/form/Button";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import IconCabin from "app/pages/.shared/IconCabin";
import IconCalendar from "app/pages/.shared/IconCalendar";
import IconClose from "app/pages/.shared/IconClose";
import IconDeparting from "app/pages/.shared/IconDeparting";
import IconLocation from "app/pages/.shared/IconLocation";
import IconMagnifyingGlass from "app/pages/.shared/IconMagnifyingGlass";
import IconOccupancies from "app/pages/.shared/IconOccupancies";
import OccupanciesDisplayLabel from "app/pages/.shared/OccupanciesDisplayLabel/OccupanciesDisplayLabel";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import LocationInspirationContainer from "app/pages/SmartDP/Search/LocationInspiration/LocationInspirationContainer";
import ClearIndicatorSdpSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/ClearIndicatorSdpSearchInput";
import DestinationInputValueFormat from "app/pages/SmartDP/Search/SDPSearchForm/DestinationInputValueFormat";
import LocationMenuList from "app/pages/SmartDP/Search/SDPSearchForm/LocationMenuList";
import LocationMenuListExtension from "app/pages/SmartDP/Search/SDPSearchForm/LocationMenuListExtension";
import SmartDPDateCalendarContainer from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPDateCalendarContainer";
import {
	defaultValues,
	validateDepartureCity,
	validateDepartureDate,
	validateDestination,
	validateSearch,
} from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";
import SmartDPSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchInput";
import TravellersRoomButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomButton";
import { cleanString, isServerSide } from "app/utils/utils";
import classNames from "classnames";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { isEmpty, orderBy } from "lodash";
import get from "lodash/get";
import PropTypes from "prop-types";
import { memo, useCallback, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useMatch } from "react-router-dom";
import "./SmartDPSearchForm.scss";
import { BRANDS, FS_QUOTE_EVENT_NAME, SDP_ROTATION_DATE_FORMAT } from "app/constants";
import CalendarDisabledView from "app/pages/SmartDP/Search/SDPSearchForm/CalendarDisabledView";
import TravellersRoomDrawerButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomDrawerButton";
import { EventCategory, HitType, useFlagship, useFsFlag } from "@flagship.io/react-sdk";
import { brandPropTypes } from "app/utils/propTypes";

const destinationResortFilterOptions = ({ data }, input = "") => {
	if (!isEmpty(data?.labels)) {
		for (const [index, value] of data.labels.entries()) {
			if (cleanString(value).match(cleanString(input))) {
				if (data.labels.length === index + 1 || data.labels.length === index + 2) {
					return true;
				}

				return false;
			}
		}
	}

	return false;
};

const SmartDPSearchForm = ({
	onSuccess = () => {},
	departureCities = [],
	destinations = [],
	cabins = [],
	initialValues = {},
	hideSidePanel = () => {},
	topDestinationsList = [],
	showMiniSDPForm = false,
	validateOnMount,
	componentToOverlayRef = {},
	brand,
	errorScrollIntoView = true,
}) => {
	const { hit: fsHit } = useFlagship();

	const isListingSdpDisplayedFlag = useFsFlag("listing_sdp_search_form_display", false);
	const isListingSdpDisplayed = isListingSdpDisplayedFlag.getValue();

	const [isTopDepartureCitiesMenuDisplayed, setIsTopDepartureCitiesMenuDisplayed] = useState(
		true
	);
	const [isTopDestinationsMenuDisplayed, setIsTopDestinationsMenuDisplayed] = useState(true);

	const { pathname } = useLocation();
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const handleSubmit = useCallback(
		(values, actions) => {
			actions.setSubmitting(false);
			onSuccess(values, pathname);
		},
		[departureCities, destinations, cabins, pathname]
	);

	// fix pour ne pas avoir les labels sur 2 lignes au premier rendu
	const mergeInitialValues = isServerSide
		? {
				occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }],
		  }
		: {
				...defaultValues,
				...initialValues,
		  };

	const isListingOrQuote = pathname.includes("/sdp/listing") || pathname === "/sdp/booking/quote";
	const isMerchPage = pathname.includes("/merch");

	const isSDPSearchPage = useMatch({
		path: "/sdp/search",
		end: true,
		caseSensitive: true,
	});
	const isSDPRootPage = useMatch({
		path: "/",
		end: true,
		caseSensitive: true,
	});

	const isSDPHomePage = isSDPRootPage || isSDPSearchPage;
	const isSticky =
		!isMobile && ["/sdp/listing", "/sdp/booking/quote"].some(path => pathname.includes(path));

	const hasCabins = isServerSide || cabins.length > 0;

	const topDepartureCities = orderBy(
		departureCities.filter(option => option?.isMain),
		["mainOrder"],
		["asc"]
	);
	const onHandleClickSearchButton = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FS_QUOTE_EVENT_NAME.CLICK_ON_SEARCH_CTA,
		});
	}, []);

	const isPPListingPage = pathname.includes("/listing");
	const isBrandAF = brand === BRANDS.AF;

	return (
		<div className="sdp-search-form">
			{(isListingOrQuote ||
				((isSDPHomePage || isMerchPage) && showMiniSDPForm) ||
				(isPPListingPage && isBrandAF && showMiniSDPForm)) &&
				isMobile && (
					<header className="sdp-search-form__header">
						<h3>
							<FormattedMessage id="sdp.search.home.input.label" />
						</h3>
						<div className="advanced-select__header-icon">
							<IconClose width={24} height={24} onClick={hideSidePanel} />
						</div>
					</header>
				)}
			<Formik
				enableReinitialize
				initialValues={mergeInitialValues}
				validate={validateSearch}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={handleSubmit}
				initialTouched={
					validateOnMount && {
						departureCity: true,
						destinationResort: true,
						travelDates: {
							departureDate: true,
							endDate: true,
						},
						duration: {
							code: true,
						},
						occupancies: true,
						cabin: true,
					}
				}
				validateOnMount={validateOnMount}
			>
				{({ values, errors, isValid, isSubmitting, setFieldValue, submitCount }) => {
					const handleTravellersConfirmation = values => {
						setFieldValue("occupancies", values);
					};

					const selectedDestinationData = destinations.find(destination => {
						// can be string if from query or number if from filters.json
						return String(destination.code) === String(values?.destinationResort?.code);
					});

					const sdpRotationUri = selectedDestinationData?.departureCities?.find(
						city => city?.code === values?.departureCity?.code
					)?.uri;

					return (
						<>
							<Form className="sdp-search-form__form" data-testid="sdp-search-form">
								<SmartDPSearchInput
									id="departureCity"
									name="departureCity"
									openMenuOnFocus={!values.departureCity}
									openMenuOnClick={!values.departureCity}
									className="sdp-search-form__field"
									data-testid="departure-city-input"
									validate={validateDepartureCity}
									label={
										<FormattedMessage id="sdp.search.departure.city.input.label" />
									}
									drawerInputLabel={
										<FormattedMessage id="sdp.search.departure.city.input.label.mobile" />
									}
									components={{
										MenuList: LocationMenuList,
										ClearIndicator: ClearIndicatorSdpSearchInput,
									}}
									formatOptionLabel={({ label = "" }) => {
										return (
											<div className="sdp-search-form__suggestion">
												{!isMobile && (
													<div className="sdp-search-form__suggestion-picto">
														<IconDeparting />
													</div>
												)}
												<span
													className="sdp-search-form__suggestion-label"
													dangerouslySetInnerHTML={{
														__html: label,
													}}
												/>
											</div>
										);
									}}
									icon={isMobile ? <IconDeparting /> : undefined}
									getOptionValue={({ code }) => code}
									getOptionLabel={({ label }) => label}
									options={
										isTopDepartureCitiesMenuDisplayed
											? topDepartureCities
											: departureCities
									}
									// on a ajouté  {|| "" } dans value de l'input pour forcer l'initialisation du champ s'il reçoit rien comme
									// valeur par defaut
									value={
										departureCities.find(({ code }) => {
											return code === get(values, "departureCity.code");
										}) || ""
									}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
									popperWidth={hasCabins ? 353 : 429}
									locationMenuLabel={
										<FormattedMessage id="sdp.search.top.departure.cities.label" />
									}
									isTopLocationMenuDisplayed={isTopDepartureCitiesMenuDisplayed}
									onInputChange={inputValue => {
										if (isEmpty(inputValue)) {
											if (!isTopDepartureCitiesMenuDisplayed) {
												setIsTopDepartureCitiesMenuDisplayed(true);
											}
										} else if (isTopDepartureCitiesMenuDisplayed) {
											setIsTopDepartureCitiesMenuDisplayed(false);
										}
									}}
									{...isListingSdpDisplayed && {
										componentToOverlayRef: componentToOverlayRef,
										overlayClassNames: "listing__sdp-search-form-overlay",
									}}
								/>

								<SmartDPSearchInput
									id="destinationResort"
									name="destinationResort"
									openMenuOnFocus={!values.destinationResort}
									openMenuOnClick={!values.destinationResort}
									validate={validateDestination}
									className="sdp-search-form__field"
									data-testid="destination-resort-input"
									filterOption={
										isTopDestinationsMenuDisplayed
											? undefined
											: destinationResortFilterOptions
									}
									label={
										<FormattedMessage id="sdp.search.destination.input.label" />
									}
									drawerInputLabel={
										<FormattedMessage id="sdp.search.destination.input.label.mobile" />
									}
									formatOptionLabel={({ labels = [] }) => {
										return (
											<div className="sdp-search-form__suggestion">
												<div className="sdp-search-form__suggestion-picto">
													<IconLocation />
												</div>
												<div className="sdp-search-form__suggestion-text">
													<span
														className="sdp-search-form__suggestion-first-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(-1).join(", "),
														}}
													/>
													<span
														className="sdp-search-form__suggestion-second-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(0, -1).join(", "),
														}}
													/>
												</div>
											</div>
										);
									}}
									{...isBrandAF && {
										menuListExtension: <LocationInspirationContainer />,
									}}
									components={{
										SingleValue: DestinationInputValueFormat,
										ClearIndicator: ClearIndicatorSdpSearchInput,
										MenuList:
											isBrandAF && !isMobile
												? LocationMenuListExtension
												: LocationMenuList,
									}}
									icon={isMobile ? <IconLocation /> : undefined}
									getOptionValue={({ code }) => code}
									getOptionLabel={({ label }) => label}
									options={
										isTopDestinationsMenuDisplayed
											? topDestinationsList
											: destinations
									}
									// on a ajouté  {|| "" } dans value de l'input pour forcer l'initialisation du champ s'il reçoit rien comme
									// valeur par defaut
									value={
										destinations.find(({ code }) => {
											return (
												code ===
												Number(get(values, "destinationResort.code"))
											);
										}) || ""
									}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
									popperWidth={hasCabins ? 353 : 429}
									locationMenuLabel={
										<FormattedMessage id="sdp.search.top.destinations.cities.label" />
									}
									isTopLocationMenuDisplayed={isTopDestinationsMenuDisplayed}
									onInputChange={inputValue => {
										if (isEmpty(inputValue)) {
											if (!isTopDestinationsMenuDisplayed) {
												setIsTopDestinationsMenuDisplayed(true);
											}
										} else if (isTopDestinationsMenuDisplayed) {
											setIsTopDestinationsMenuDisplayed(false);
										}
									}}
									{...isListingSdpDisplayed && {
										componentToOverlayRef: componentToOverlayRef,
										overlayClassNames: "listing__sdp-search-form-overlay",
									}}
								/>

								<SmartDPDateCalendarContainer
									id="travelDates"
									name="travelDates"
									data-testid="departure-date-input"
									validate={validateDepartureDate}
									icon={isMobile ? <IconCalendar /> : undefined}
									className="sdp-search-form__field sdp-search-form__field--small"
									popperOffset={hasCabins ? [72, 0] : [-25, 0]}
									calendarDisabledView={
										<CalendarDisabledView
											mainMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="sdp.calendar.unavailable.date.label" />
												) : (
													<FormattedMessage id="sdp.calendar.no.destination.no.departure.city.label" />
												)
											}
											secondaryMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="sdp.calendar.unavailable.date.sublabel" />
												) : null
											}
										/>
									}
									label={
										<FormattedMessage id="sdp.search.departure.date.input.label" />
									}
									uri={sdpRotationUri}
									handleDayChange={({
										durationsOfSelectedDepartureDate = [],
										selectedEndDate,
									}) => {
										// durationsOfSelectedDepartureDate [{duration, ed}]

										if (isEmpty(durationsOfSelectedDepartureDate)) {
											setFieldValue("duration", { code: "" });
										} else if (selectedEndDate) {
											const formattedSelectedEndDate = format(
												new Date(selectedEndDate),
												SDP_ROTATION_DATE_FORMAT
											);

											const dataOfSelectedDepartureDate = durationsOfSelectedDepartureDate.find(
												data => data.ed === formattedSelectedEndDate
											);

											if (dataOfSelectedDepartureDate) {
												setFieldValue("duration", {
													code: dataOfSelectedDepartureDate.duration,
												});
											} else {
												setFieldValue("duration", { code: "" });
											}
										}
									}}
									{...isListingSdpDisplayed && {
										componentToOverlayRef: componentToOverlayRef,
										overlayClassNames: "listing__sdp-search-form-overlay",
									}}
								/>

								{hasCabins && (
									<SmartDPSearchInput
										id="cabin"
										name="cabin"
										openMenuOnFocus
										className="sdp-search-form__field sdp-search-form__field--small"
										data-testid="cabin-input"
										isSearchable={false}
										icon={isMobile ? <IconCabin /> : undefined}
										label={
											<FormattedMessage id="sdp.search.cabin.input.label" />
										}
										getOptionLabel={({ label = "", suffix = "" }) =>
											`${label}${suffix ? `, ${suffix}` : ""}`
										}
										components={{
											ClearIndicator: ClearIndicatorSdpSearchInput,
										}}
										getOptionValue={({ code }) => code}
										options={cabins}
										value={cabins.find(({ code }) => {
											return code === get(values, "cabin.code");
										})}
										popperWidth={isListingOrQuote ? 180 : 274}
										{...isListingSdpDisplayed && {
											componentToOverlayRef: componentToOverlayRef,
											overlayClassNames: "listing__sdp-search-form-overlay",
										}}
									/>
								)}

								{isMobile ? (
									<TravellersRoomDrawerButton
										icon={<IconOccupancies />}
										className={classNames("sdp-search-form__field", {
											"sdp-search-form__field--fullsize": !hasCabins,
										})}
										id="travellersRooms"
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										validateOnMount={validateOnMount}
									/>
								) : (
									<TravellersRoomButton
										className={classNames("sdp-search-form__field", {
											"sdp-search-form__field--fullsize": !hasCabins,
										})}
										id="travellersRooms"
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										stickyMode={isSticky}
										validateOnMount={validateOnMount}
										{...isListingSdpDisplayed && {
											componentToOverlayRef: componentToOverlayRef,
											overlayClassNames: "listing__sdp-search-form-overlay",
										}}
									/>
								)}

								<div className="sdp-search-form__footer">
									{isListingOrQuote && !isMobile ? (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
										>
											<IconMagnifyingGlass />
										</Button>
									) : (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
											onClick={onHandleClickSearchButton}
										>
											<FormattedMessage id="sdp.search.cta.label" />
										</Button>
									)}
								</div>
							</Form>
							{/* Do not display error message on validationOnMount (redirection from emirates) */}
							{!isValid && submitCount >= 1 && (
								<div className="sdp-search-form__error">
									<FormErrorMessages
										errors={errors}
										isSubmitting={isSubmitting}
										submitCount={submitCount}
										disableErrorsDetails={true}
										message={
											<FormattedMessage id="sdp.search.form.error.message" />
										}
										scrollInToView={errorScrollIntoView}
									/>
								</div>
							)}
						</>
					);
				}}
			</Formik>
		</div>
	);
};

SmartDPSearchForm.propTypes = {
	onSuccess: PropTypes.func,
	departureCities: PropTypes.array,
	destinations: PropTypes.array,
	cabins: PropTypes.array,
	initialValues: PropTypes.object,
	hideSidePanel: PropTypes.func,
	topDestinationsList: PropTypes.array,
	showMiniSDPForm: PropTypes.bool,
	validateOnMount: PropTypes.bool,
	componentToOverlayRef: PropTypes.object,
	handleFormError: PropTypes.func,
	brand: brandPropTypes,
	errorScrollIntoView: PropTypes.bool,
};

export default memo(SmartDPSearchForm);
